import { getData } from './request.js'

export function getProjectList() {
    return getData({
        url: '/mapi/project/list',
        method: 'get'
    })
}

export function addProject(data) {
    return getData({
        url: '/mapi/project/add',
        method: 'post',
        data: data
    })
}

export function updateProject(data) {
    return getData({
        url: '/mapi/project/update',
        method: 'put',
        data: data
    })
}

export function deleteProject(data) {
    return getData({
        url: '/mapi/project/delete',
        method: 'delete',
        data: data
    })
}
