import { getData } from './request.js'

export function getAdvertiserList() {
    return getData({
        url: '/mapi/advertiser/list',
        method: 'get'
    })
}

export function addAdvertiser(data) {
    return getData({
        url: '/mapi/advertiser/add',
        method: 'post',
        data: data
    })
}

export function updateAdvertiser(data) {
    return getData({
        url: '/mapi/advertiser/update',
        method: 'put',
        data: data
    })
}

export function deleteAdvertiser(data) {
    return getData({
        url: '/mapi/advertiser/delete',
        method: 'delete',
        data: data
    })
}
