<template>
  <div class="container">
    <el-form :loading="loading" ref="form" :model="preFrom" label-width="80px"></el-form>
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">
          新建广告主
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-refresh-right" size="mini" @click="getDataList">
          刷新
        </el-button>
      </el-col>
    </el-row>
    <el-table border stripe :loading="loading" :data="DataList" style="margin-top: 5px;">
      <el-table-column label="ID" align="center" prop="id"/>
      <el-table-column label="账户名称" align="center" prop="userName"/>
      <el-table-column label="所属业务组ID" align="center" prop="projectId">
        <template slot-scope="scope">
          {{ getProjectName(scope.row.projectId) }}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" prop="createTime">
        <template slot-scope="scope">
          {{ parseTime(scope.row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column label="更新时间" align="center" prop="updateTime">
        <template slot-scope="scope">
          {{ parseTime(scope.row.updateTime) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="140px">
        <template slot-scope="scope">
          <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)">
            修改
          </el-button>
          <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row.id)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 弹窗 -->
    <el-dialog :title="dialog.title" :visible.sync="dialog.open" width="30%" append-to-body>
      <el-form v-loading="loading" :model="preFrom" ref="ruleForm" label-width="100px">
        <el-form-item label="广告主ID" prop="id">
          <el-input v-model="preFrom.id" placeholder="请输入广告主ID"></el-input>
        </el-form-item>
        <el-form-item label="账户名称" prop="userName">
          <el-input v-model="preFrom.userName" placeholder="请输入广告主名称"></el-input>
        </el-form-item>
        <el-form-item label="业务组" prop="project">
          <el-select
              v-model="preFrom.projectId"
              filterable
              value-key="id"
              placeholder="选择业务组"
              style="width: 100%"
              :loading="loadingProjects"
          >
            <!-- 显示名称+账号ID -->
            <el-option
                v-for="item in projectOptions"
                :key="item.id"
                :label="item.projectName"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item style="margin-top: 20px">
          <el-button type="primary" @click="onSubmit">提交</el-button>
          <el-button @click="resetForm()">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {checkTokenBeforeRouteEnter} from '@/utils/authoVerify'
import {getAdvertiserList, addAdvertiser, updateAdvertiser, deleteAdvertiser} from "@/api/advertiser";
import { cloneDeep } from 'lodash';
import {parseTime} from "@/utils/untilsMethod";
import {getProjectList} from "@/api/project";

export default {
  beforeRouteEnter(to, from, next) {
    checkTokenBeforeRouteEnter(to, from, next);
  },
  data() {
    return {
      loading: false,
      loadingProjects: false,
      DataList: [],
      projectOptions: [],
      dialog: {
        open: false,
        title: '',
        type: ''
      },
      preFrom: {
        id: undefined,
        userName: undefined,
        projectId: undefined,
      },
      form: {}
    }
  },
  created() {
    this.getDataList()
    this.getProjectData()
  },
  methods: {
    parseTime,
    getProjectName(projectId){
      const project = this.projectOptions.find(p => p.id === projectId);
      return project ? project.projectName : '';
    },
    getProjectData(){
      this.loadingProjects = true;
      getProjectList()  // 已存在于项目中的广告主接口方法
          .then(res => {
            this.projectOptions = res.data || [];
          })
          .finally(() => this.loadingProjects = false);
    },
    // 删除
    handleDelete(id) {
      this.$confirm("删除后将不可恢复, 是否继续?", "删除广告主", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteAdvertiser({id: id}).then(response => {
          this.$message.success(response.msg)
          this.getDataList()
        })
      })
    },
    // 修改
    handleUpdate(item) {
      this.dialog.title = '修改广告主'
      this.dialog.type = 'update'
      this.dialog.open = true
      this.preFrom = cloneDeep(item)
    },
    // 新增
    handleAdd() {
      this.dialog.title = '新建广告主'
      this.dialog.type = 'add'
      this.dialog.open = true
    },
    // 查询列表
    getDataList() {
      this.loading = true
      getAdvertiserList().then(response => {
        this.DataList = response.data
      }).finally(() => {
        this.loading = false
      })
    },
    // 提交表单
    onSubmit() {
      if (!this.preFrom.userName || !this.preFrom.id) {
        this.$message.error("账户ID或名称不能为空")
        return
      }
      this.getForm()
      const apiCall = this.dialog.type === 'add'
          ? addAdvertiser(this.form)
          : updateAdvertiser(this.form)

      apiCall.then(response => {
        this.$message.success(response.msg)
        this.getDataList()
        this.dialog.open = false
        this.resetForm()
      }).catch(() => {
        this.$message.error("请求错误")
      })
    },
    // 重置表单
    resetForm() {
      this.preFrom = { id: undefined, userName: undefined }
      this.form = {}
    },
    getForm() {
      this.form = cloneDeep(this.preFrom);
      this.form.createTime = undefined;
      this.form.updateTime = undefined
    },
  }
}
</script>

<style>
.container {
  height: auto;
  display: flex;
  flex-direction: column;
}
</style>
